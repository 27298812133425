<template>
  <div class="company-summary-wrapper">
    <div class="company-summary-box">
      <div class="company-summary-title-box">
        <div class="company-summary-title-top-box">
          <div class="separted">
            <el-image class="separted-img" :src="require('@/assets/static/separate.png')" />
          </div>
          <div class="title">公司概况</div>
        </div>
        <div class="company-summary-title-bottom-box">
          COMPANY PROFILE
        </div>
      </div>
      <div class="company-summary-content-box">
        <div class="company-img-box">
          <el-image class="company-img" :src="require('@/assets/static/company-img.png')" />
        </div>
        <div class="company-introduce-box">
          <div class="line"></div>
          <div class="introduce">
            <p>苏州昇荣电子有限公司成立于2016年，通过关键技术创新和精准的客户服务，致力于推动智能制造和产业升级。</p>
            <p>公司以工装治具和非标自动化加工制造为核心业务，致力于消费电子、半导体、医疗以及其他高科技行业自动化设备的研发设计、制造调试、软件开发以及其他服务的专业型高科技企业。公司拥有一支在先进结构设计、自动控制、视觉检测、工装夹具开发等方面具有丰富经验的专业技术队伍，为诸多客户提供了成熟的技术方案和成套设备。另外，公司在设备的OEM组装方面也具有丰富的质量管控流程和成熟的管理经验。</p>
            <p>公司自主研发的无人机停机库系统和自动化设备已获得多项发明和实用新型专利，并在多个技术领域取得了突破。</p>
          </div>
        </div>
        <div class="clear-div" />
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style scoped lang="less">
.company-summary-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
 .company-summary-box {
    width: 100%;
    max-width: 992px;
    box-sizing: border-box;
    .company-summary-title-box {
      width: 100%;
      padding: 0 80px;
      box-sizing: border-box;
      .company-summary-title-top-box {
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        .separted {
          .separted-img {
            width: 5px;
            height: 30px;
          }
        }
        .title {
          font-weight: 700;
          font-size: 31px;
          line-height: 38px;
          color: #202020;
          margin-left: 24px;
        }
      }
      .company-summary-title-bottom-box {
        margin-top: 3px;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        color: #c0c0c0;
        padding-left: 35px;
      }
    }
    .company-summary-content-box {
      width: 100%;
      height: 495px;
      padding: 0 80px;
      margin-top: 20px;
      box-sizing: border-box;
      position: relative;
      .company-img-box {
        width: 495px;
        height: 495px;
        width: 100%;
        position: absolute;
        left: 80px;
        z-index: 0;
        .company-img {
          width: 495px;
          height: 495px;
        }
      }
      .company-introduce-box {
        width: 548px;
        padding: 32px;
        position: absolute;
        z-index: 1;
        background: #fff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        top: 40px;
        right: 80px;
        .line {
          width: 89px;
          height: 1px;
          border: 1px solid #4f4f4f;
          background: #4f4f4f;
          margin-bottom: 15px;
        }
        .introduce {
          font-weight: 300;
          font-size: 14px;
          line-height: 30px;
          color: #4f4f4f;
        }
      }
      .company-introduce-box::after {
        content: "";
        display: block;
        clear: both;
      }
    }
  } 
}
@media (max-width: 768px) {
  .company-summary-wrapper {
    margin-top: 19px;
    .company-summary-box {
      .company-summary-title-box {
        padding: 0 35px;
        .company-summary-title-top-box {
          width: 100%;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          .separted {
            .separted-img {
              width: 4px;
              height: 22px;
            }
          }
          .title {
            font-size: 20px;
            line-height: 24px;
            margin-left: 17px;
          }
        }
        .company-summary-title-bottom-box {
          font-size: 12px;
          line-height: 15px;
          padding-left: 22px;
        }
      }
      .company-summary-content-box {
        width: 100%;
        height: 275px;
        box-sizing: border-box;
        padding: 0;
        .company-img-box {
          left: 34px;
          width: 275px;
          height: 275px;
          .company-img {
            width: 275px;
            height: 275px;
          }
        }
        .company-introduce-box {
          width: 271px;
          position: absolute;
          right: 17px;
          padding: 8px;
          top: 7px;
          .line {
            display: none;
          }
          .introduce {
            font-size: 9px;
            line-height: 14px;
          }
        }
      }
    }
  }
}
</style>