<template>
  <div class="new-detail-box-wrap">
    <nav-bar @open-dialog="openDialog" />
    <div class="new-detail-box">
      <div class="bread-crumb-box">
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }" class="">网站首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/recruit-bridge' }">招聘列表</el-breadcrumb-item>
          <el-breadcrumb-item style="position: relative; top: 2px;">招聘详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div v-show="pageType === 'detail'">
        <new-content :detail="newInfo" />
      </div>
      <div v-show="pageType === 'list'">
        <new-content v-for="detail in newInfos" :key="detail.id" :detail="detail" />
      </div>
    </div>
    <custom-footer />
    <contract-dialog ref="conDialog" />
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowRight } from '@element-plus/icons-vue'
import { NavBar, CustomFooter, ContractDialog } from '@/components'
import NewContent from './components/new-content.vue'
import { lists, detail } from '@/api/recruit'

const route = useRoute()
const newInfo = ref({})
const newInfos = ref([])
const conDialog = ref(false)
const pageType = ref('list') // list, detail

const openDialog = () => {
  conDialog.value.visible = true
}

const getDetail = async id => {
  pageType.value = 'detail'
  const res = await detail({ id })
  if (res?.code === 0) {
    newInfo.value = res.data
  }
}

const getDetails = async type => {
  pageType.value = 'list'
  const res = await lists({ type })
  if (res?.code === 0) {
    newInfos.value = res.data
  }
}

watch(() => route.query.id, (nv, ov) => {
  nv && getDetail(nv)
})

onMounted(() => {
  const { id, type } = route.query
  id && getDetail(id)
  type && getDetails(type)
})
</script>
<style lang="less" scoped>
.new-detail-box-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .new-detail-box {
    width: 100%;
    max-width: 850px;
    min-height: 276px;
    .bread-crumb-box {
      font-weight: 400;
      font-size: 20px;
      line-height: 42px;
      .bread-crumb {
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
        color: #666;
      }
    }
  }
}
@media(max-width: 768px) {
  .new-detail-box-wrap {
    .new-detail-box {
      padding: 0 20px;
      min-height: 331px;
    }
  }
}
</style>