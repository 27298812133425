import axios from 'axios'

const service = axios.create({
  baseURL: process?.env?.VUE_APP_API, // url = base url + request url
  timeout: 150000,
  // withCredentials: true // send cookies when cross-domain requests
})

// Request interceptors
service.interceptors.request.use(
  (config) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    // if (UserModule.token) {
    // config.headers['X-Access-Token'] = UserModule.token
    // }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    // Some example codes here:
    // You can change this part for your own usage.
    // status == 200: success
    // code === 0: success
    // code === 1: invalid access token

    const res = response.data
    return res
  },
  (error) => {
    let errorInfo = error.response
    let msg
    if (!errorInfo) {
      msg = '接口错误'
    } else {
      msg = '接口错误2'
    }
    return Promise.reject(msg)
  }
)

export default service
