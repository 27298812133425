<template>
  <div class="nav-bar-box-wrap">
    <div class="nav-bar-box">
      <el-row align="middle">
        <el-col :span="12" :sm="{span: 12}" :md="{span: 8}" :lg="{span: 6}">
          <div class="website-logo-box" @click="handleToIndex">
            <el-image class="website-logo" :src="require('@/assets/static/logo.png')" />
          </div>
        </el-col>
        <el-col :span="12" :sm="{span: 12}" :md="{ span: 16 }" :lg="{span: 18}">
          <div class="website-manipulate hidden-xs-only">
            <el-row>
              <el-col class="website-manipulate-box" :span="24">
                <el-dropdown  style="margin-right: 20px;" @command="handleCommand">
                  <el-button type="text">
                    解决方案<el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item v-for="product in productList" :key="product.id" :command="product.id">{{ product.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <el-button type="text" @click="handleCompanyIntroduce">公司简介</el-button>
                <el-button type="text" @click="handleFactory">工厂概况</el-button>
                <el-button type="text" @click="handleCulture">企业文化</el-button>
                <el-button type="text" @click="handleRecruit">人才招聘</el-button>
                <el-button type="primary" @click="openDialog">联系我们</el-button>
              </el-col>
            </el-row>
          </div>
          <div class="website-manipulate-drawer hidden-sm-and-up">
            <el-row justify="end" align="middle">
              <el-col class="website-manipulate-drawer-col" :span="24">
                <div class="menu-box" @click="visible = true">
                  <i class="iconfont icon-menu" />
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <nav-bar-drawer
    :visible="visible"
    @drawer-close="handleDrawerClose"
    @open-dialog="openDialog"
  />
</template>
<script setup>
import { defineProps, defineEmits, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
// import { Search, HomeFilled, TakeawayBox, CollectionTag, FolderOpened } from '@element-plus/icons-vue'
import { Logo } from '@/assets'
import  NavBarDrawer from './components/nav-bar-drawer.vue'
import { lists } from '@/api/product'
import '@/assets/icon/iconfont.css'

const emit = defineEmits(['openDialog'])

const router = useRouter()
let productList = ref([])
let visible = ref(false)

const handleDrawerClose = () => {
  visible = false
}

const openDialog = () => {
  emit('openDialog')
}

const handleCommand = id => {
  const query = { id }
  router.push({ path: `/product-detail`, query })
}



const handleCompanyIntroduce = () => {
  router.push({ path: `/company-detail` })
}

const handleFactory = () => {
  router.push({ path: `/factory-detail` })
}

const handleCulture = () => {
  router.push({ path: `/company-culture` })
}

const handleRecruit = () => {
  router.push({ path: `/recruit-bridge` })
}

const getList = async () => {
  const res = await lists({ page: 0 })
  if (res?.code === 0) {
    productList.value = res?.data
  }
}

const handleToIndex = () => {
  console.log('to index')
  router.push({ path: `/` })
}

getList()

// export default defineComponent({
//   name: 'nav-bar',
//   props: {
//     productList: Array
//   },
//   emits: ['openDialog'],
//   components: {
//     Search, HomeFilled, TakeawayBox, CollectionTag, FolderOpened, NavBarDrawer
//   },
//   setup(props, { emit }) {
//     const productList = ref(props.productList || [])
//     const dataMap = reactive({
//       visible: false,
//       handleDrawerClose() {
//         dataMap.visible = false
//       },
//       openDialog() {
//         emit('openDialog')
//       }
//     })
//     onMounted(() => {
//       // console.log(dataMap.productList)
//     })
//     return { ...toRefs(dataMap) }
//   }
// })

</script>
<style lang="less" scoped>
.nav-bar-box-wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 37px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .nav-bar-box {
    width: 100%;
    max-width: 1270px;
    .website-logo-box {
      width: 139px;
      height: 37px;
      cursor: pointer;
      .website-logo {
        width: 139px;
        height: 37px;
      }
    }
    .website-manipulate {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 16px;
      .website-manipulate-box {
        min-width: 370px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .website-manipulate-drawer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 16px;
      .website-manipulate-drawer-col {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .search-box, .menu-box {
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 14px;
          .iconfont {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
@media(max-width: 768px) {
  .nav-bar-box-wrap {
    padding: 27px 20px;
    .nav-bar-box {
      .website-logo-box {
        width: 135px;
        height: 36px;
        cursor: pointer;
        .website-logo {
          width: 135px;
          height: 36px;
        }
      }
      .website-manipulate-drawer {
        .website-manipulate-drawer-col {
          .menu-box {
            width: 36px;
            height: 36px;
            .iconfont {
              justify-content: flex-end;
              font-size: 36px;
            }
          }
        }
      }
    }
  }
}
</style>