<template>
  <div class="home-box">
    <nav-bar @open-dialog="openDialog" />
    <!-- <h1 style="text-align: center;">网站建设中</h1> -->
    <!-- <advertising-slogn /> -->
    <div class="advertising-slogn">
      <el-image class="advertising-img" :src="require('@/assets/static/advertising4.png')" />
    </div>
    <!-- <div class="contact-us-box-wrap">
      <el-button class="contact-us-btn" @click="openDialog">联系我们</el-button>
    </div> -->
    <!-- <advertising-introduce /> -->
    <company-summary />
    <new-release />
    <product-introduce />
    <custom-footer />
    <contract-dialog ref="conDialog" />
  </div>
</template>
<script setup>
import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue'
import { NavBar, CustomFooter, ContractDialog } from '@/components'
import AdvertisingSlogn from './component/advertising-slogn.vue'
import AdvertisingIntroduce from './component/advertising-introduce.vue'
import CompanySummary from './component/company-summary.vue'
import NewRelease from './component/new-release.vue'
import ProductIntroduce from './component/product-introduce.vue'
import { articles } from '@/api/article'

const conDialog = ref(false)
const dataMap = reactive({
  productList: []
})

const openDialog = () => {
  conDialog.value.visible = true
}

</script>
<style scoped lang="less">
@import "~@/style/animation/variables.less";
.home-box {
  width: 100%;
  box-sizing: border-box;
  .advertising-slogn {
    width: 100%;
    display: flex;
    justify-content: center;
    .advertising-img {
      width: 100%;
      max-width: 1920px;
      // max-width: 1250px;
    }
    .advertising-slogn-img {
      // width: 100%;
      // max-width: 1450px;
    }
  }
  .contact-us-box-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
    .contact-us-btn {
      width: 123px;
      height: 52px;
      background: #0170c1;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      
    }
  }
}
@media(min-width: 1000px) {
  .home-box {
    .contact-us-box-wrap {
      display: none;
    }
  }
}
</style>