import http from '@/utils/request'

export const lists = params =>
  http({
    url: `product/list`,
    params
  })

export const detail = params =>
  http({
    url: `product/findById`,
    params
  })