import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ElIconModules from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import '@/style/common/common.less'
import App from './App.vue'
import { router }from './config'

const app = createApp(App)
//  统一注册el-icon图标
for(let iconName in ElIconModules){
  app.component(iconName,ElIconModules[iconName])
}
app.use(ElementPlus)
app.use(router)
app.mount('#app')
console.log(process.env)
