import http from '@/utils/request'

export const lists = params =>
  http({
    url: `news/front/listByAll`,
    params
  })

export const detail = params =>
  http({
    url: `news/front/detail`,
    params
  })