<template>
  <div class="new-detail-box-wrap">
    <nav-bar @open-dialog="openDialog" />
    <div class="new-detail-box">
      <div class="bread-crumb-box">
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }" class="">网站首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/factory-detail' }">工厂概况</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="title-box">
        <div class="left-title">产品加工</div>
        <div class="right-line" />
      </div>
      <div class="image-list-box">
        <el-image :src="require('@/assets/factory/f-1-1.png')" />
      </div>
      <div class="image-list-box left-right-position">
        <div class="left-position-box">
          <el-image :src="require('@/assets/factory/f-2-1.png')" />
          <el-image :src="require('@/assets/factory/f-2-2.png')" />
        </div>
        <div class="right-position-box">
          <el-image :src="require('@/assets/factory/f-2-3.png')" />
        </div>
      </div>
      <div class="image-list-box" style="margin-top: 20px;">
        <el-image :src="require('@/assets/factory/f-3-1.png')" />
      </div>
      <div class="title-box">
        <div class="left-title">产品组装</div>
        <div class="right-line" />
      </div>
      <div class="image-list-box left-right-position">
        <el-image :src="require('@/assets/factory/f-4-1.png')" />
        <el-image :src="require('@/assets/factory/f-4-2.png')" />
      </div>
      <div class="image-list-box left-right-position">
        <el-image :src="require('@/assets/factory/f-5-1.png')" />
        <el-image :src="require('@/assets/factory/f-5-2.png')" />
      </div>
      <div class="image-list-box" style="margin-top: 20px;">
        <el-image :src="require('@/assets/factory/f-6-1.png')" />
      </div>
      <div class="image-list-box left-right-position">
        <el-image :src="require('@/assets/factory/f-7-1.png')" />
        <el-image :src="require('@/assets/factory/f-7-2.png')" />
      </div>
      <div class="title-box">
        <div class="left-title">产品检验</div>
        <div class="right-line" />
      </div>
      <div class="image-list-box" style="margin-top: 20px;">
        <el-image :src="require('@/assets/factory/f-8-1.png')" />
      </div>
      <div class="image-list-box" style="margin-top: 20px;">
        <el-image :src="require('@/assets/factory/f-8-2.png')" />
      </div>
      <div class="title-box">
        <div class="left-title">员工休息</div>
        <div class="right-line" />
      </div>
      <div class="image-list-box" style="margin-top: 20px;">
        <el-image :src="require('@/assets/factory/f-9-1.png')" />
      </div>
    </div>
    <custom-footer />
    <contract-dialog ref="conDialog" />
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowRight } from '@element-plus/icons-vue'
import { NavBar, CustomFooter, ContractDialog } from '@/components'
import { lists, detail } from '@/api/recruit'

const route = useRoute()
const newInfo = ref({})
const newInfos = ref([])
const conDialog = ref(false)
const pageType = ref('list') // list, detail

const openDialog = () => {
  conDialog.value.visible = true
}

const getDetail = async id => {
  pageType.value = 'detail'
  const res = await detail({ id })
  if (res?.code === 0) {
    newInfo.value = res.data
  }
}

const getDetails = async type => {
  pageType.value = 'list'
  const res = await lists({ type })
  if (res?.code === 0) {
    newInfos.value = res.data
  }
}

watch(() => route.query.id, (nv, ov) => {
  nv && getDetail(nv)
})

// onMounted(() => {
//   const { id, type } = route.query
//   id && getDetail(id)
//   type && getDetails(type)
// })
</script>
<style lang="less" scoped>
.new-detail-box-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .new-detail-box {
    width: 100%;
    max-width: 850px;
    min-height: 276px;
    .bread-crumb-box {
      font-weight: 400;
      font-size: 20px;
      line-height: 42px;
      .bread-crumb {
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
        color: #666;
      }
    }
    .title-box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      margin: 30px 0 15px;
      .left-title {
        width: 140px;
        font-weight: 600;
        font-size: 32px;
        color: #0170C1;
      }
      .right-line {
        width: calc(100% - 140px);
        height: 1px;
        background: #0170C1;
      }
    }
    .image-list-box {
      width: 100%;
    }
    .left-right-position {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 15px;
      .left-position-box {
        width: 53.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
      }
      .right-position-box {
        width: 46%;
      }
    }
  }
}
@media(max-width: 768px) {
  .new-detail-box-wrap {
    .new-detail-box {
      padding: 0 20px;
      min-height: 331px;
      .title-box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin: 15px 0 7px;
        .left-title {
          width: 70px;
          font-weight: 600;
          font-size: 16px;
          color: #0170C1;
        }
        .right-line {
          width: calc(100% - 70px);
          height: 1px;
          background: #0170C1;
        }
      }
    }
  }
}
</style>