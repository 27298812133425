import Home from '@/views/home/home'
// import Detail from '@/views/detail/detail'
import NewBridge from '@/views/new/new-bridge'
import NewDetail from '@/views/new/new-detail'
import ProductBridge from '@/views/product/product-bridge'
import ProductAllBridge from '@/views/product/product-all-bridge'
import ProductDetail from '@/views/product/product-detail'
import CompanyCulture from '@/views/company-culture/index'
import RecruitBridge from '@/views/recruit/bridge'
import RecruitDetail from '@/views/recruit/detail'
import FactoryDetail from '@/views/factory/index'
import CompanyDetail from '@/views/company/index'

export default{
  Home,
  // Detail,
  NewBridge,
  NewDetail,
  ProductBridge,
  ProductAllBridge,
  ProductDetail,
  CompanyCulture,
  RecruitBridge,
  RecruitDetail,
  FactoryDetail,
  CompanyDetail
}