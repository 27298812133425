<template>
  <div class="footer-box-wrap">
    <div class="footer-pc-box hidden-xs-only">
      <div class="footer-top">
        <div class="left-info">
          <div class="contact-title">联系我们</div>
          <div class="line" />
          <div class="contact-info-box phone-box">
            <div class="icon">
              <el-image :src="require('@/assets/static/phone.png')" />
            </div>
            <div class="text">电话：0512-63938443</div>
          </div>
          <div class="contact-info-box mail-box">
            <div class="icon">
              <el-image :src="require('@/assets/static/mail.png')" />
            </div>
            <div class="text">邮箱：sales@sanroe.com</div>
          </div>
          <div class="contact-info-box phone-box">
            <div class="icon">
              <el-image :src="require('@/assets/static/location.png')" />
            </div>
            <div class="text">地址：江苏省苏州市吴江区长安路268号</div>
          </div>
        </div>
        <div class="logo-box">
          <el-image class="logo" :src="require('@/assets/static/logo-transparent.png')" />
        </div>
      </div>
      <div class="footer-bottom">
        <div class="bottom-info">
          <div class="copyright-box">版权所有©2022 苏州昇荣电子有限公司 保留所有权利</div>
          <div class="ICP-box">备案号：苏ICP备2022037624</div>
        </div>
      </div>
    </div>
    <div class="footer-mobile-box hidden-sm-and-up">
      <div class="footer-top">
        <div class="left-info">
          <div class="contact-title">联系我们</div>
          <div class="logo-box">
            <el-image :src="require('@/assets/static/logo-transparent.png')" />
          </div>
        </div>
      </div>
      <div class="footer-middle">
        <div class="line" />
        <div class="contact-info-box phone-box">
          <div class="icon">
            <el-image :src="require('@/assets/static/phone.png')" />
          </div>
          <div class="text">电话：0512-63938443</div>
        </div>
        <div class="contact-info-box mail-box">
          <div class="icon">
            <el-image :src="require('@/assets/static/mail.png')" />
          </div>
          <div class="text">邮箱：sales@sanroe.com</div>
        </div>
        <div class="contact-info-box phone-box">
          <div class="icon">
            <el-image :src="require('@/assets/static/location.png')" />
          </div>
          <div class="text">地址：江苏省苏州市吴江区长安路268号</div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="bottom-info">
          <div class="copyright-box">版权所有©2022 苏州昇荣电子有限公司<br />保留所有权利</div>
          <div class="ICP-box">备案号：<br />苏ICP备2022037624</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'custom-footer',
  components: [],
  props: [],
  setup() {
    return {}
  }
})
</script>
<style scoped lang="less">
  .footer-box-wrap {
    width: 100%;
    box-sizing: border-box;
    background: #0170c1;
    margin-top: 206px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    .footer-pc-box {
      width: 100%;
      padding: 46px 0 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .footer-top {
        width: 900px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-info {
          box-sizing: border-box;
          .contact-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 158.52%;
          }
          .line {
            width: 99px;
            height: 0;
            border-bottom: 1px solid;
            margin: 9px 0 16px;
          }
          .contact-info-box {
            display: flex;
            margin-top: 9px;
            .icon {
              width: 20px;
              height: 20px;
            }
            .text {
              margin-left: 16px;
              font-weight: 300;
              font-size: 15px;
              line-height: 158.52%;
            }
          }
        }
        .logo-box {
          width: 285px;
          height: 97px;
          .logo {
            width: 285px;
            height: 97px;
          }
        }
      }
      .footer-bottom {
        width: 100%;
        margin-top: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 47px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        font-weight: 300;
        font-size: 12px;
        line-height: 158.52%;
        color: #fff;
        opacity: 0.6;
        .bottom-info {
          width: 516px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  @media(max-width: 768px) {
    .footer-box-wrap {
      margin-top: 66px;
      .footer-mobile-box {
        width: 100%;
        padding: 26px 0 0px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .footer-top {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 30px;
          .left-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .contact-title {
              font-weight: 700;
              font-size: 18px;
              line-height: 158.52%;
            }
            .logo-box {
              width: 142px;
              height: 48px;
              .logo {
                width: 142px;
                height: 48px;
              }
            }
          }
        }
        .footer-middle {
          width: 100%;
          box-sizing: border-box;
          padding: 0 30px;
          .line {
            width: 99px;
            height: 0;
            border-bottom: 1px solid;
            margin: 9px 0 16px;
          }
          .contact-info-box {
            display: flex;
            margin-top: 9px;
            .icon {
              width: 20px;
              height: 20px;
            }
            .text {
              margin-left: 16px;
              font-weight: 300;
              font-size: 10px;
              line-height: 158.52%;
            }
          }
        }
        .footer-bottom {
          width: 100%;
          margin-top: 27px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 47px;
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          font-weight: 300;
          font-size: 6px;
          line-height: 158.52%;
          color: #fff;
          opacity: 0.6;
          padding: 0 30px;
          .bottom-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
</style>