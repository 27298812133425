<template>
  <div class="product-introduce-box">
    <div class="product-introduce-wrap">
      <div class="product-introduce-title-box">
        <div class="product-introduce-title-top-box">
          <div class="separted">
            <el-image class="separted-img" :src="require('@/assets/static/separate.png')" />
          </div>
          <div class="title">产品介绍</div>
        </div>
        <div class="product-introduce-title-bottom-box">
          PRODUCT INTRODUCTION
        </div>
      </div>
      <div class="product-introduce-content-reponsive-box">
        <el-row :gutter="25" style="display: flex; justify-content: space-between; align-items: center;">
          <el-col v-for="item, index in  products" :key="index" :xs="24" :sm="24" :md="12" class="xxx-el-col" @click="handleToDetail2(item.type)">
            <el-image :src="item.icon" style="width: 100%;" />
          </el-col>
        </el-row>
      </div>
      <!-- <div class="product-introduce-content-slide-box">
        <div class="product-introduce-slide-wrap hidden-xs-only">
          <swiper
            :slides-per-view="3"
            :space-between="-100"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :autoplay="{ disableOnInteraction: false }"
            loop
            class="swiper-main-box"
          >
            <swiper-slide
              class="product-introduce-content-box"
              style="padding: 40px; box-sizing: border-box;"
              v-for="(item, index) in products"
              :key="index"
            >
              <div
                class="content-wrap"
                style="width: 270px; box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1); display: flex; flex-direction: column; justify-content: center; align-items: center; cursor: pointer;"
                @click="handleToDetail2(item.type)"
              >
                <div class="product-icon">
                  <el-image :src="item.icon" />
                </div>
                <div class="zh-title">
                  {{ item.zhTitle }}
                </div>
                <div class="en-title">
                  {{ item.enTitle }}
                </div>
                <div class="content">
                  {{ item.content }}
                </div>
              </div>
            </swiper-slide> -->
            <!-- 如果需要导航按钮 左右箭头。如果放置在swiper-container外面，需要自定义样式。-->
            <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </swiper> -->
        <!-- </div> -->
        <!-- <div class="product-introduce-mobile-wrap hidden-sm-and-up">
          <swiper
            :slides-per-view="1"
            loop
            class="swiper-main-box"
          >
            <swiper-slide
              class="product-introduce-content-box"
              v-for="(item, index) in products"
              :key="index"
              style="padding: 40px;"
            >
              <div
                class="content-wrap"
                style="width: 270px; box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1); display: flex; flex-direction: column; justify-content: center; align-items: center; cursor: pointer;"
                @click="handleToDetail2(item.type)"
              >
                <div class="product-icon">
                  <el-image :src="item.icon" />
                </div>
                <div class="zh-title">
                  {{ item.zhTitle }}
                </div>
                <div class="en-title">
                  {{ item.enTitle }}
                </div>
                <div class="content">
                  {{ item.content }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper"
import { useRouter } from 'vue-router'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay])

const router = useRouter()

const products = ref([
  {
    icon: require(`@/assets/static/pro-med.png`),
    zhTitle: '半导体/医疗行业',
    enTitle: 'SEMI/MEDICAL',
    content: '清洁等级较高的半导体及医疗行业产品',
    type: 2
  },
   {
    icon: require(`@/assets/static/pro-uav.png`),
    zhTitle: '无人机库',
    enTitle: 'UAV STATION',
    content: '无人机、停机仓及中控平台',
    type: 1
  },
  {
    icon: require(`@/assets/static/pro-sem.png`),
    zhTitle: '自动化组件',
    enTitle: 'SUBASSEMBLY',
    content: '设备制造行业的成套部件组装',
    type: 4
  },
  {
    icon: require(`@/assets/static/pro-smt.png`),
    zhTitle: 'SMT行业',
    enTitle: 'AUTOMATION',
    content: '成套设备设计、制造、调试以及售后服务',
    type: 5
  }
  // {
  //   icon: require(`@/assets/static/MACHINING PARTS.png`),
  //   zhTitle: '机械加工件',
  //   enTitle: 'MACHINING PARTS',
  //   content: '工装、冶具等精密机械加工',
  //   type: 3
  // }
])

const handleToDetail2 = type => {
  const query = { type }
  router.push({ path: 'product-bridge', query })
}

onMounted(() => {})
</script>
<script>
// // template name
// const name = 'product-introduce'

// // template components
// const components = {}

// // template props
// const props = {}
// export default defineComponent({ name, components, props })
</script>
<style scoped lang="less">
.product-introduce-box {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  margin-top: 67px;
  .product-introduce-wrap {
    width: 100%;
    max-width: 992px;
    box-sizing: border-box;
    .product-introduce-title-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 80px;
      .product-introduce-title-top-box {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .separted {
          width: 5px;
          height: 30px;
          .separted-img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-weight: 700;
          font-size: 31px;
          line-height: 38px;
          color: #202020;
          margin-left: 24px;
        }
      }
      .product-introduce-title-bottom-box {
        width: 100%;
        box-sizing: border-box;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        color: #c0c0c0;
        margin-left: 29px;
        margin-top: 3px;
      }
    }
    .product-introduce-content-reponsive-box {
      margin-top: 24px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 80px;
      .xxx-el-col {
        margin-bottom: 20px;
      }
    }
    // .product-introduce-content-slide-box {
    //   width: 100%;
    //   min-width: 957px;
    //   box-sizing: border-box;
    //   display: flex;
    //   justify-content: space-between;
    //   margin-top: 23px;
    //   .product-introduce-toggle-box {
    //     width: 78px;
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     .toggle-img {
    //       width: 16px;
    //       height: 26.5px;
    //     }
    //   }
    //   .product-introduce-next-box {
    //     justify-content: flex-end;
    //   }
    //   .product-introduce-slide-wrap {
    //     width: 100%;
    //     box-sizing: border-box;
    //     position: relative;
    //     padding: 0px 40px;
    //     .swiper-main-box {
    //       position: initial;
    //       margin: 0px 37px 16px;
    //       box-sizing: border-box;
    //       padding: 0px 0;
    //       .swiper-button-prev:after{
    //           display: none;
    //       }
    //       .swiper-button-next:after{
    //           display: none;
    //       }
    //       /*再自定义样式*/
    //       .swiper-button-prev{
    //           background: url('~@/assets/static/prev.png') no-repeat;
    //           background-size: 16px 26.5px;
    //       }
    //       .swiper-button-next{
    //           background: url('~@/assets/static/next.png') no-repeat;
    //           background-size: 16px 26.5px;
    //       }
    //       .product-introduce-content-box {
    //         box-sizing: border-box;
    //         .product-icon {
    //           width: 70px;
    //           height: 70px;
    //           margin-top: 50px;
    //         }
    //         .zh-title {
    //           width: 100%;
    //           font-weight: 700;
    //           font-size: 20px;
    //           line-height: 158.52%;
    //           text-align: center;
    //           margin-top: 27px;
    //         }
    //         .en-title {
    //           width: 100%;
    //           font-weight: 300;
    //           font-size: 12px;
    //           line-height: 158.52%;
    //           text-align: center;
    //           color: rgba(0, 0, 0, 0.6);
    //         }
    //         .content {
    //           font-weight: 300;
    //           font-size: 12px;
    //           line-height: 158.52%;
    //           color: rgba(0, 0, 0, 0.7);
    //           margin-top: 25px;
    //           margin-bottom: 18px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
@media(max-width: 768px) {
  .product-introduce-box {
    margin-top: 29px;
    padding: 0 35px;
    .product-introduce-wrap {
      .product-introduce-title-box {
        padding: 0;
        .product-introduce-title-top-box {
          .separted {
            width: 4px;
            height: 22px;
            .separted-img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            font-size: 20px;
            line-height: 24px;
            color: #202020;
            margin-left: 17px;
          }
        }
        .product-introduce-title-bottom-box {
          font-size: 12px;
          line-height: 15px;
          margin-left: 22px;
          margin-top: 6px;
        }
      }
      .product-introduce-content-reponsive-box {
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        margin-top: 10px;
        .xxx-el-col {
          margin-bottom: 10px;
        }
      }
      .product-introduce-content-slide-box {
        width: 100%;
        min-width: 100%;
        box-sizing: border-box;
        margin-top: 0;
        .product-introduce-mobile-wrap {
          width: 100%;
          box-sizing: border-box;
          overflow: hidden;
          .product-introduce-content-box {
            display: flex;
            justify-content: center;
            align-items: center;
            .content-wrap {
              width: 100%;
              width: 270px;
              box-sizing: border-box;
              box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
              .product-icon {
                width: 66px;
                height: 66px;
                margin-top: 50px;
              }
              .zh-title {
                width: 100%;
                font-weight: 700;
                font-size: 20px;
                line-height: 158.52%;
                text-align: center;
                margin-top: 27px;
              }
              .en-title {
                width: 100%;
                font-weight: 300;
                font-size: 12px;
                line-height: 158.52%;
                text-align: center;
                color: rgba(0, 0, 0, 0.6);
              }
              .content {
                font-weight: 300;
                font-size: 12px;
                line-height: 158.52%;
                color: rgba(0, 0, 0, 0.7);
                margin-top: 25px;
                margin-bottom: 18px;
              }
            }
            .title {
              padding: 24px 20px 30px;
              font-weight: 500;
              font-size: 15px;
              line-height: 120%;
              color: #161616;
            }
            .content {
              padding: 0 20px;
              font-weight: 300;
              font-size: 12px;
              line-height: 186.02%;
              color: #4f4f4f;
            }
            .date {
              padding: 0 20px;
              text-align: right;
              font-weight: 300;
              font-size: 10px;
              line-height: 186.02%;
              color: #d0d0d0;
            }
            .more {
              width: 100%;
              height: 43px;
              background: #0170c1;
              color: #fff;
              font-size: 14px;
              font-weight: 300;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 11px;
            }
          }
        }
      }
    }
  }
}
</style>
