import http from '@/utils/request'

export const lists = params =>
  http({
    url: `hire/list`,
    params
  })

export const detail = params =>
  http({
    url: `hire/findById`,
    params
  })