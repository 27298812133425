<template>
  <div class="new-release-box">
    <div class="new-release-wrap">
      <div class="new-release-title-box">
        <div class="new-release-title-top-box">
          <div class="separted">
            <el-image class="separted-img" :src="require('@/assets/static/separate.png')" />
          </div>
          <div class="title">新闻发布</div>
        </div>
        <div class="new-release-title-bottom-box">
          NEWS RELEASES
        </div>
      </div>
      <div class="new-release-content-slide-box">
        <div class="new-release-slide-wrap hidden-xs-only">
          <swiper
            :slides-per-view="1"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :autoplay="{ disableOnInteraction: false }"
            loop
            class="swiper-main-box"
          >
            <swiper-slide
              class="new-release-content-box"
              style="padding: 30px;"
              v-for="(item, index) in news"
              :key="index"
            >
              <div class="title">
                {{ item.title }}
              </div>
              <div class="content">
                {{ item.summary }}
              </div>
              <div class="date">{{ dateZh(item.createdDate) }}</div>
              <div class="more" @click="handelToNewDetail(item)">MORE</div>
            </swiper-slide>
            <!-- 如果需要导航按钮 左右箭头。如果放置在swiper-container外面，需要自定义样式。-->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </swiper>
        </div>
        <div class="new-release-mobile-wrap hidden-sm-and-up">
          <swiper
            :slides-per-view="1"
            loop
            class="swiper-main-box"
          >
            <swiper-slide
              class="new-release-content-box"
              v-for="(item, index) in news"
              :key="index"
            >
              <div class="title">
                {{ item.title }}
              </div>
              <div class="content">
                {{ item.summary }}
              </div>
              <div class="date">{{ dateZh(item.createdDate) }}</div>
              <div class="more" @click="handelToNewDetail(item)">MORE</div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper"
import { lists } from '@/api/new'
import { dateZh } from '@/utils/format'
import { eh_base } from '@/utils/escapeHTML'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay])

const router = useRouter()
const news = ref([])

const onSwiper = (swiper) => {
  console.log(swiper)
}
const onSlideChange = () => {
  console.log('slide change')
}
const swiperNext = () => {}

const swiperPref = () => {}

const getLists = async () => {
  const res = await lists()
  if (res.code === 0) {
    news.value = res.data
  }
}

const handelToNewDetail = ({ id }) => {
  router.push({ path: `/new-detail/${id}` })
}

onMounted(() => {
  getLists()
})
</script>
<script>
// // template name
// const name = 'new-release'

// // template components
// const components = {}

// // template props
// const props = {}
// export default defineComponent({ name, components, props })
</script>
<style scoped lang="less">
.new-release-box {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  margin-top: 67px;
  .new-release-wrap {
    width: 100%;
    max-width: 992px;
    box-sizing: border-box;
    .new-release-title-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 80px;
      .new-release-title-top-box {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .separted {
          width: 5px;
          height: 30px;
          .separted-img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-weight: 700;
          font-size: 31px;
          line-height: 38px;
          color: #202020;
          margin-left: 24px;
        }
      }
      .new-release-title-bottom-box {
        width: 100%;
        box-sizing: border-box;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        color: #c0c0c0;
        margin-left: 29px;
        margin-top: 3px;
      }
    }
    .new-release-content-slide-box {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      margin-top: 23px;
      .new-release-toggle-box {
        width: 78px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .toggle-img {
          width: 16px;
          height: 26.5px;
        }
      }
      .new-release-next-box {
        justify-content: flex-end;
      }
      .new-release-slide-wrap {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        padding: 0 40px;
        .swiper-main-box {
          position: initial;
          margin: 0px 37px 16px;
          box-sizing: border-box;
          box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
          .swiper-button-prev:after{
              display: none;
          }
          .swiper-button-next:after{
              display: none;
          }
          /*再自定义样式*/
          .swiper-button-prev{
              background: url('~@/assets/static/prev.png') no-repeat;
              background-size: 16px 26.5px;
          }
          .swiper-button-next{
              background: url('~@/assets/static/next.png') no-repeat;
              background-size: 16px 26.5px;
          }
          .new-release-content-box {
            width: 100%;
            box-sizing: border-box;
            padding: 30px;
            .title {
              font-weight: 500;
              font-size: 25px;
              line-height: 120%;
              color: #161616;
            }
            .content {
              font-weight: 400;
              font-size: 16px;
              line-height: 186.02%;
              color: #4f4f4f;
              margin-top: 26px;
            }
            .date {
              width: 100%;
              box-sizing: border-box;
              margin-top: 24px;
              text-align: right;
              font-weight: 300;
              font-size: 16px;
              line-height: 186.02%;
              color: #d0d0d0;
              text-align: right;
            }
            .more {
              width: 100%;
              box-sizing: border-box;
              font-weight: 300;
              font-size: 16px;
              line-height: 185.02%;
              text-align: right;
              color: #161616;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
@media(max-width: 768px) {
  .new-release-box {
    margin-top: 29px;
    padding: 0 35px;
    .new-release-wrap {
      .new-release-title-box {
        padding: 0;
        .new-release-title-top-box {
          .separted {
            width: 4px;
            height: 22px;
            .separted-img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            font-size: 20px;
            line-height: 24px;
            color: #202020;
            margin-left: 17px;
          }
        }
        .new-release-title-bottom-box {
          font-size: 12px;
          line-height: 15px;
          margin-left: 22px;
          margin-top: 6px;
        }
      }
      .new-release-content-slide-box {
        width: 100%;
        box-sizing: border-box;
        margin-top: 22px;
        .new-release-mobile-wrap {
          width: 100%;
          box-sizing: border-box;
          border-radius: 10px;
          box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          .new-release-content-box {
            .title {
              padding: 24px 20px 30px;
              font-weight: 500;
              font-size: 15px;
              line-height: 120%;
              color: #161616;
            }
            .content {
              padding: 0 20px;
              font-weight: 300;
              font-size: 12px;
              line-height: 186.02%;
              color: #4f4f4f;
            }
            .date {
              padding: 0 20px;
              text-align: right;
              font-weight: 300;
              font-size: 10px;
              line-height: 186.02%;
              color: #d0d0d0;
            }
            .more {
              width: 100%;
              height: 43px;
              background: #0170c1;
              color: #fff;
              font-size: 14px;
              font-weight: 300;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 11px;
            }
          }
        }
      }
    }
  }
}
</style>
