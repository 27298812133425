<template>
  <el-drawer
    :size="300"
    v-model="drawer"
    :with-header="false"
    :show-close="false"
    :before-close="handleDrawerClose"
    custom-class="nav-bar-drawer-box"
  >
    <div class="drawer-box">
      <el-menu @select="handleMenu">
        <el-menu-item index="index">首页</el-menu-item>
        <el-menu-item index="product">解决方案</el-menu-item>
        <el-menu-item index="company-detail">公司简介</el-menu-item>
        <el-menu-item index="factory-detail">工厂概况</el-menu-item>
        <!-- <el-menu-item index="company">企业介绍</el-menu-item> -->
        <el-menu-item index="culture">企业文化</el-menu-item>
        <el-menu-item index="news">新闻发布</el-menu-item>
        <el-menu-item index="people">人才招聘</el-menu-item>
      </el-menu>
      <div class="contact-us-area">
        <el-button type="primary" @click="openDialog">联系我们</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'nav-bar-drawer',
  components: {},
  emits: ['drawerClose', 'openDialog'],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit }) {
    const router = useRouter()
    const dataMap = reactive({
      drawer: false,
      menus: [],
      handleDrawerClose() {
        emit('drawerClose')
      },
      openDialog() {
        emit('openDialog')
      },
      handleMenu(e) {
        console.log(e)
        if (e === 'culture') {
          router.push({ path: `/company-culture` })
        }
        if (e === 'index') {
          router.push({ path: `/` })
        }
        if (e === 'product') {
          router.push({ path: `/product-all-bridge` })
        }
        if (e === 'news') {
          router.push({ path: `/new-bridge` })
        }
        if (e === 'people') {
          router.push({ path: `/recruit-bridge` })
        }
        if (e === 'factory-detail') {
          router.push({ path: `/factory-detail` })
        }
        if (e === 'company-detail') {
          router.push({ path: `/company-detail` })
        }
        dataMap.handleDrawerClose()
      }
    })
    onMounted(() => {
    })
    watch(() => props.visible, drawer => {
      dataMap.drawer = drawer
    })
    return { ...toRefs(dataMap) }
  }
})
</script>
<style scoped lang="less">
:deep(.el-menu){
  border-right: 0;
}
.nav-bar-drawer-box {
  .el-drawer__body {
    padding: 0 !important;
  }
  .drawer-box {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    .contact-us-area {
      margin-top: 25px;
    }
    .avatar-box {
      width: 130px;
      height: 130px;
      .avatar-img {
        width: 100%;
        height: 100%;
        border-radius: 65px;
      }
    }
    .blog-info {
      width: 100%;
      margin-top: 30px;
      font-size: 20px;
      box-sizing: border-box;
      .info-item-box {
        width: 100%;
        text-align: center;
        .info-value {
          margin-top: 10px;
        }
      }
      .divider {
        width: 100%;
        border: 2px dashed;
        box-sizing: border-box;
      }
    }
  }
}

</style>