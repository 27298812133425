<template>
  <div class="new-detail-box-wrap">
    <nav-bar @open-dialog="openDialog" />
    <div class="new-detail-box">
      <div class="bread-crumb-box">
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }" class="">网站首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/company-detail' }">公司简介</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="title-box">
        <div class="left-title-en">COMPANY PROFILE</div>
        <div class="left-title-ch">企业简介</div>
      </div>
      <div class="title-line">
        <div class="left-line" />
        <div class="rogjt-line" />
      </div>
      <div class="left-right-position-box">
        <div class="left-position-box">
          <p>苏州昇荣电子有限公司成立于2016年，通过关键技术创新和精准的客户服务，致力于推动智能制造和产业升级。</p>
          <p>公司以精密机械加工和非标自动化设备制造为核心业务，致力于服务消费电子、半导体、医疗等行业，提供从研发设计到制造调试及软件开发的专业型高科技企业。</p>
          <p>公司拥有一支在先进结构设计、自动控制、视觉检测、工装夹具开发等方面具有丰富经验的专业技术队伍，为诸多客户提供了成熟的技术方案和成套设备。另外，公司在设备的OEM组装方面也具有丰富的质量管控流程和成熟的管理经验。</p>
          <p>公司自主研发的无人机停机库系统和自动化设备已获得多项发明和实用新型专利，并在多个技术领域取得了突破。</p>
        </div>
        <div class="right-position-box">
          <el-image :src="require('@/assets/company/company-introduce.png')" />
        </div>
      </div>
      <div class="title-box">
        <div class="left-title-en">QUALITY POLICY</div>
        <div class="left-title-ch">质量方针</div>
      </div>
      <div class="title-line">
        <div class="left-line" />
        <div class="rogjt-line" />
      </div>
      <div class="policy-box" style="display: flex; gap: 20px; flex-wrap: wrap; padding: 35px 0;">
        <div style="width: calc((100% - 20px) / 2); border: ">
          <el-image :src="require('@/assets/company/policy-1.png')" />
        </div>
        <div style="width: calc((100% - 20px) / 2)">
          <el-image :src="require('@/assets/company/policy-2.png')" />
        </div>
        <div style="width: calc((100% - 20px) / 2)">
          <el-image :src="require('@/assets/company/policy-3.png')" />
        </div>
        <div style="width: calc((100% - 20px) / 2)">
          <el-image :src="require('@/assets/company/policy-4.png')" />
        </div>
      </div>
    </div>
    <custom-footer />
    <contract-dialog ref="conDialog" />
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowRight } from '@element-plus/icons-vue'
import { NavBar, CustomFooter, ContractDialog } from '@/components'
import { lists, detail } from '@/api/recruit'

const route = useRoute()
const newInfo = ref({})
const newInfos = ref([])
const conDialog = ref(false)
const pageType = ref('list') // list, detail

const openDialog = () => {
  conDialog.value.visible = true
}

const getDetail = async id => {
  pageType.value = 'detail'
  const res = await detail({ id })
  if (res?.code === 0) {
    newInfo.value = res.data
  }
}

const getDetails = async type => {
  pageType.value = 'list'
  const res = await lists({ type })
  if (res?.code === 0) {
    newInfos.value = res.data
  }
}

watch(() => route.query.id, (nv, ov) => {
  nv && getDetail(nv)
})

// onMounted(() => {
//   const { id, type } = route.query
//   id && getDetail(id)
//   type && getDetails(type)
// })
</script>
<style lang="less" scoped>
.new-detail-box-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .new-detail-box {
    width: 100%;
    max-width: 850px;
    min-height: 276px;
    .bread-crumb-box {
      font-weight: 400;
      font-size: 20px;
      line-height: 42px;
      .bread-crumb {
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
        color: #666;
      }
    }
    .title-box {
      width: 100%;
      .left-title-en {
        font-size: 22px;
        color: #0170C1;
        font-weight: 600;
        margin: 10px 0;
      }
      .left-title-ch {
        width: 140px;
        font-size: 32px;
        color: #000000;
        font-weight: 600;
        margin: 0 0 10px;
      }
    }
    .title-line {
      width: 100%;
      height: 4px;
      background: #E6E6E6;
      .left-line {
        width: 125px;
        height: 4px;
        background: #0170C1;
      }
    }
    .left-right-position-box {
      display: flex;
      gap: 15px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 25px 0;
      .left-position-box {
        width: 68%;
        font-size: 22px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        p {
          text-indent: 2em;
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
      .right-position-box {
        width: 32%;
      }
    }
  }
}
@media(max-width: 768px) {
  .new-detail-box-wrap {
    .new-detail-box {
      padding: 0 20px;
      min-height: 331px;
      .title-box {
        width: 100%;
        .left-title-en {
          font-size: 12px;
          color: #0170C1;
          font-weight: 600;
          margin: 10px 0;
        }
        .left-title-ch {
          width: 140px;
          font-size: 16px;
          color: #000000;
          font-weight: 600;
          margin: 0 0 10px;
        }
      }
      .title-line {
        width: 100%;
        height: 4px;
        background: #E6E6E6;
        .left-line {
          width: 60px;
          height: 4px;
          background: #0170C1;
        }
      }
      .left-right-position-box {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 25px 0;
        .left-position-box {
          width: 60%;
          font-size: 7px;
          display: flex;
          flex-direction: column;
          gap: 0;
          p {
            text-indent: 2em;
            margin-block-start: 0;
            margin-block-end: 0;
          }
        }
        .right-position-box {
          width: 40%;
        }
      }
    }
  }
}
</style>