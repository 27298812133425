<template>
  <div class="new-content-box-wrap">
    <div class="new-content-box">
      <div class="title">{{ newInfo.title }}</div>
      <div class="summary">{{ newInfo.summary  }}</div>
      <div class="content" v-html="newInfo.content" />
      <div class="date">{{ dateZh(newInfo.createdDate) }}</div>
      <div class="line"/>
    </div>
  </div>
</template>
<script setup>
import { defineProps, watch, ref, onMounted } from 'vue'
import { dateZh } from '@/utils/format'
import { eh_base } from '@/utils/escapeHTML'

const props = defineProps({
  detail: Object
})

const newInfo = ref({
  title: '',
  content: '',
  createdDate: ''
})

watch(() => props.detail, (nv, ov) => {
  console.log(nv)
  console.log(ov)
  newInfo.value = nv
  
})

onMounted(() => {
  console.log(props.detail)
})
</script>
<style lang="less" scoped>
.new-content-box-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  .new-content-box {
    width: 100%;
    box-sizing: border-box;
    .title {
      font-weight: 500;
      font-size: 27px;
      line-height: 42px;
      color: #000;
    }
    .summary {
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      margin-top: 21px;
      color: #000;
    }
    .content {
      margin-top: 21px;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: #000;
    }
    .date {
      margin-top: 6px;
      width: 100%;
      text-align: right;
      font-weight: 500;
      font-size: 15px;
      line-height: 42px;
      color: #9A9A9A;
    }
    .line {
      margin-top: 7px;
      border-bottom: 1px solid #eee;
    }
  }
}
@media(max-width: 768px) {
  .new-content-box-wrap {
    .new-content-box {
      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
      }
      .content {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
      }
      .date {
        font-weight: 300;
        font-size: 15px;
        line-height: 42px;
      }
    }
  }
}
</style>