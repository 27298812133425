import { createRouter, createWebHashHistory } from 'vue-router'
import Page from '@/views'

const routes = [
  { path: '/', component: Page.Home },
  { path: '/detail/:id', component: Page.Detail },
  { path: '/new-bridge', component: Page.NewBridge },
  { path: '/new-detail/:id', component: Page.NewDetail },
  { path: '/product-all-bridge', component: Page.ProductAllBridge },
  { path: '/product-bridge', component: Page.ProductBridge },
  { path: '/product-detail', component: Page.ProductDetail },
  { path: '/company-culture', component: Page.CompanyCulture },
  { path: '/recruit-bridge', component: Page.RecruitBridge },
  { path: '/recruit-detail', component: Page.RecruitDetail },
  { path: '/factory-detail', component: Page.FactoryDetail },
  { path: '/company-detail', component: Page.CompanyDetail }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router