<template>
  <el-dialog
    v-model="visible"
    width="300px"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form
      :model="form"
    >
      <el-form-item>
        <el-input
          v-model="form.email"
          placeholder="您的邮箱"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.phone"
          placeholder="您的手机号"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.name"
          placeholder="您的姓名"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.company"
          placeholder="您的公司名"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.content"
          type="textarea"
          placeholder="我们如何帮助您"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="footer">
        <el-button class="fotter-btn" @click="handleSubmit">提交</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineProps, defineEmits, defineExpose, watch } from 'vue'
import { save } from '@/api/contract'
import { ElMessage } from 'element-plus'

const visible = ref(false)
const form = ref({})

const handleClose = () => {
  visible.value = false
}

const handleSubmit = async () => {
  const res = await save(form.value)
  if (res.code === 0) {
    ElMessage({
      message: '提交成功',
      type: 'success',
    })
  } else {
    ElMessage({
      message: res.msg,
      type: 'error',
    })
  }
  visible.value = false
}

defineExpose({ visible })
</script>
<style lang="less" scoped>
.dialog-box {
  width: 500px;
}
.footer{
  width: 100%;
  box-sizing: border-box;
  .fotter-btn {
    width: 100%;
    background: #000000;
    border: 2px solid #000000;
    border-radius: 10px;
    color: #fff;
  }
}
@media(max-width: 768px) {
  .dialog-box {
    width: 400px;
  }
}
</style>