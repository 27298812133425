<template>
  <div class="new-detail-box-wrap">
    <nav-bar @open-dialog="openDialog" />
    <div class="new-detail-box">
      <div class="bread-crumb-box">
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }" class="">网站首页</el-breadcrumb-item>
          <el-breadcrumb-item>企业文化</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="wrap">
        <div class="box">
          <div class="content-image-box">
            <el-image :src="require('@/assets/static/vision.png')" />
          </div>
          <div class="title-image-box">
            公司愿景
          </div>
          <div class="sub-title-box">
            <span class="main-title">成为智造行业可持续发展的创新者</span>
            <span class="sub-title">Become an innovator in the intelligent manufacturing field.</span>
          </div>
          <div class="content-image-box">
            <el-image :src="require('@/assets/static/mission.png')" />
          </div>
          <div class="title-image-box">
            公司使命
          </div>
          <div class="sub-title-box">
            <span class="main-title">助力智造产业 成就制造梦想</span>
            <span class="sub-title">Working for intelligent manufacturing; Realize enterprise value.</span>
          </div>
          <div class="content-image-box">
            <el-image :src="require('@/assets/static/operation-guideline.png')" />
          </div>
          <div class="title-image-box">
            经营理念
          </div>
          <div class="sub-title-box">
            <span class="main-title">同客户一起进步 和员工一同成长 与供应商协作共赢</span>
            <span class="sub-title">Progress with customers; Grow with employees; Collaborate with suppliers.</span>
          </div>
          <div class="content-image-box">
            <el-image :src="require('@/assets/static/core-value.png')" />
          </div>
          <div class="title-image-box">
            核心价值观
          </div>
          <div class="sub-title-box">
            <span class="main-title">目标 执行 担当 协作</span>
            <span class="sub-title">Objective; Implementation; Responsibility; Collabration.</span>
          </div>
        </div>
      </div>
    </div>
    <custom-footer />
    <contract-dialog ref="conDialog" />
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowRight } from '@element-plus/icons-vue'
import { NavBar, CustomFooter, ContractDialog } from '@/components'
import { lists, detail } from '@/api/product'

const route = useRoute()
const newInfo = ref({})
const newInfos = ref([])
const conDialog = ref(false)
const pageType = ref('list') // list, detail

const openDialog = () => {
  conDialog.value.visible = true
}

const getDetail = async id => {
  pageType.value = 'detail'
  const res = await detail({ id })
  if (res?.code === 0) {
    newInfo.value = res.data
  }
}

const getDetails = async type => {
  pageType.value = 'list'
  const res = await lists({ type })
  if (res?.code === 0) {
    newInfos.value = res.data
  }
}

watch(() => route.query.id, (nv, ov) => {
  nv && getDetail(nv)
})

onMounted(() => {
  const { id, type } = route.query
  id && getDetail(id)
  type && getDetails(type)
})
</script>
<style lang="less" scoped>
.new-detail-box-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .new-detail-box {
    width: 100%;
    max-width: 850px;
    min-height: 276px;
    .bread-crumb-box {
      font-weight: 400;
      font-size: 20px;
      line-height: 42px;
      .bread-crumb {
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
        color: #666;
      }
    }
    .wrap {
      width: 100%;
      margin-top: 40px;
      .box {
        width: 100%;
        .content-image-box {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .title-image-box {
          display: inline;
          padding: 7px 20px;
          border-bottom-left-radius: 18px;
          border-top-right-radius: 18px;
          background: #1E62B9;
          color: #fff;
          font-size: 16px;
        }
        .sub-title-box {
          width: 100%;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .main-title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media(max-width: 768px) {
  .new-detail-box-wrap {
    .new-detail-box {
      padding: 0 20px;
      min-height: 331px;
    }
  }
}
</style>