<template>
  <div class="new-detail-box-wrap">
    <nav-bar @open-dialog="openDialog" />
    <div class="new-detail-box">
      <div class="bread-crumb-box">
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }" class="">网站首页</el-breadcrumb-item>
          <el-breadcrumb-item>职位列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="image-box" style="margin-top: 40px;">
        <el-image :src="require('@/assets/static/recruit.png')" />
      </div>
      <div v-show="pageType === 'detail'">
        <new-content :detail="newInfo" />
      </div>
      <!-- 第一版傻逼需求 做了两天要改 不想删了 -->
      <div v-show="pageType === 'list'">
        <!-- <new-content v-for="detail in newInfos" :key="detail.id" :detail="detail" /> -->
        <div class="table">
          <div class="table-item">
            <div class="item-title">职位</div>
            <div class="item-code">人数</div>
            <div class="item-operate"><span style="margin-right: 20px;">操作</span></div>
          </div>
        </div>
        <div v-for="detail in newInfos" :key="detail.id" :detail="detail" class="table">
          <div class="table-item">
            <div class="item-title">{{ detail.position }}</div>
            <div class="item-code">{{ detail.num }}</div>
            <div class="item-operate">
              <el-button type="text" @click="handleToDetail(detail.id)">点击进入</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <custom-footer />
    <contract-dialog ref="conDialog" />
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ArrowRight } from '@element-plus/icons-vue'
import { NavBar, CustomFooter, ContractDialog } from '@/components'
import NewContent from './components/new-content.vue'
import { lists, detail } from '@/api/recruit'

const route = useRoute()
const router = useRouter()
const newInfo = ref({})
const newInfos = ref([])
const conDialog = ref(false)
const pageType = ref('list') // list, detail

const openDialog = () => {
  conDialog.value.visible = true
}

const getLists = async type => {
  pageType.value = 'list'
  const res = await lists()
  if (res?.code === 0) {
    newInfos.value = res.data.content
  }
}

const handleToDetail = id => {
  const query = { id }
  router.push({ path: `/recruit-detail`, query })
}

onMounted(() => {
  getLists()
})
</script>
<style lang="less" scoped>
.new-detail-box-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .new-detail-box {
    width: 100%;
    max-width: 850px;
    min-height: 276px;
    .bread-crumb-box {
      font-weight: 400;
      font-size: 20px;
      line-height: 42px;
      .bread-crumb {
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
        color: #666;
      }
    }
  }
}
.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .table-item {
    margin-top: 10px;
    border-bottom: 1px solid #efefef;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .item-title,
    .item-code {
      width: 40%;
      word-wrap: break-word;
      word-break: normal;
      padding: 10px 20px 10px 0;
      font-size: 13px;
    }
    .item-code {
      color: #898989;
    }
    .item-operate {
      width: 20%;
      text-align: right;
    }
  }
}
.table:nth-child(1) {
  margin-top: 40px;
}
@media(max-width: 768px) {
  .new-detail-box-wrap {
    .new-detail-box {
      padding: 0 20px;
      min-height: 331px;
    }
  }
  .table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .table-item {
      margin-top: 10px;
      border-bottom: 1px solid #efefef;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .item-title,
      .item-code {
        width: 40%;
        word-wrap: break-word;
        word-break: normal;
        padding: 10px 20px 10px 0;
        font-size: 13px;
      }
      .item-code {
        color: #898989;
      }
      .item-operate {
        width: 20%;
        text-align: right;
      }
    }
  }
  .table:nth-child(1) {
    margin-top: 40px;
  }
}
</style>